body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  color: hsl(209, 61%, 16%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  letter-spacing:  0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h3 {
  font-size: 1.25rem;
}


h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 0;
  font-size: .75rem;
}



.title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.50rem;
}

.underline {
  width: 6rem;
  height: 0.03rem;
  background: #040404;
  margin-left: auto;
  margin-right: auto;
}

.btn-container {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.filter-btn {
  background: linear-gradient(45deg, #f3cbd5, #f9e7e4);
  border: none;
  font-size: 1.0rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.active {
  background: linear-gradient(45deg, #FF416C, #FF4B2B);
  color: rgb(255, 255, 255);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.filter-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.filter-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.section-center {
  margin-top: 4%;
  width: 90vw;
  margin: 100 auto;
  max-width: 800px;
  display: grid;
  gap: 1rem 0.6rem;
  justify-items: center;
  row-gap: 20px;
}

.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}


.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid hsl(360, 71%, 66%);
  border-radius: 0.25rem;
  display: block;
}

.logo {
  object-fit: cover;
  height: auto;
  width: 200px;
  border-radius: 0.25rem;  
}

.item-info-header {
  display: flex;
  justify-content: space-between;
  color: #050505;
  font: bold;
  float: left;
  margin-right: 10px;
}

.item-info-catagory {
  display: flex;
  justify-content: space-between;
  color: #170505;
  font: bold;
  float: left;
  margin-right: 10px;
}

.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: #e01d1d;
  font: bold;
  font-size: 15rem;
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
}


@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}


.mainBackground {
  background: #f7f7f7;
}

.CardBackground {
  background: #ffffff;
}


.imageDevider {
  width: 6rem;
  height: 0.15rem;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
.imageSeperator {
  width: 6rem;
  height: 0.15rem;
  background: #cfc5c5;
  margin-left: auto;
  margin-right: auto;
}

.photoCard {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid hsl(360, 71%, 66%);
  border-radius: 0.25rem;
  display: block;
}

body {
  background-color: lightgrey;
  color: rgb(3, 3, 3);
  font-size: 0.475rem;
}
.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.itemHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  width: 300px;
}


.CardSection {
  width: 310px;
  margin-left: 10%;
  margin-top: 0%;
  /* margin-bottom: 20%; */
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
div.image::before {
  content:url(http://placehold.it/350x150);
}​


div.scroll {
  margin: 4px, 4px;
  padding: 4px;
  background-color: #08c708;
  width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}


.left-image {
  float: left;
  margin-right: 10px;
}

.right-image {
  float: right;
  margin-left: 10px;
}





.row {
  margin-left: 20px;
  color: white;
}

.row__posters {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 1px;
}

.row__posters::-webkit-scrollbar {
  display: inline;
}

.row__poster {
  width: 100%;
  object-fit: contain;
  max-height: 100px;
  margin-right: 10px;
  transition: transform 450ms;
}

.row__posterLarge {
  max-height: 250px;
}

.row__posterLarge:hover {
  transform: scale(1.09);
}

.row__poster:hover {
  transform: scale(1.08);
}


.navMenu {
  background: #f7f7f7;
  margin-top: 0%;
  margin-bottom: 0%;
  color:#f7f7f7;
  
}