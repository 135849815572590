.mainBackground {
  margin-top: 3%;
  margin-bottom: 20%;
}

.textStyle {
  color: black;
}

.styled-table {
  color: black;
  border-collapse: collapse;
  margin: auto;
  font-size: 2em;
  font-family: sans-serif;
  max-width: 1000px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.btn {
  border: none;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.btn-edit {
  background-color: #008cba;
}
.btn-delete {
  background-color: #f44336;
}
.btn-view {
  background-color: #e7e7e7;
  color: black;
}

.btn-add {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: #009879;
  border-color: #009879;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 10%;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #1d5f2a;
}
label {
  color: rgb(8, 7, 7);
  font-size: larger;
}

.styled-textbox {
  color: black;
  border-collapse: collapse;
  margin: auto;
  font-size: 2em;
  font-family: sans-serif;
  max-width: 1000px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 2%;
}
