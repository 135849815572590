


.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: 0.5%;
  }
  
  img {
    max-width: 100%
  }
  
  .image {
    flex-basis: 40%
  }
  
  .text {
    font-size: 20px;
    padding-left: 20px;
  }