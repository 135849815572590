.App {
  text-align: center;
}

html {
  background-color: #f7f7f7;
}
.clear {
  float: left;
  margin-left: 240px;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  background: #ffffff;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
}

.form input {
  background: hsl(0, 0%, 100%);
  border-color: transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.item {
  background: #fff;
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  margin: 2rem auto;
  align-items: center;
  border-radius: 0.25rem;
}
.item {
  padding: 1rem 2rem;
}
.item h4 {
  margin-bottom: 0;
}

.title {
  margin-top: 0%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
}
.zoomed {
  transition: transform 0.3s ease; /* Add a smooth transition */
  transform: scale(1.2); /* Zoom effect */
}
