.menu {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.menuItem {
  padding: 10px 20px;
  color: #fff;
  text-decoration: none; 
  font-weight: bold;
  border-radius: 5px;
  background-color: #f04938;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.menuItem:hover {
  transform: translateY(-3px); /* Add slight hover effect */
}
.menuWrapper {
    height: 100px; /* Set the fixed height */
   /*  overflow-y: auto; Add vertical scrollbar if content exceeds height */
  }
  